<template>
  <main class="main" 
    style="font-family: 'Proxima Nova' !important; margin-top: 55px; margin-bottom: 0" id="top">
    <app-navbar />

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-0 overflow-hidden" id="banner">

      <div 
        class="bg-holder overlay" 
        style="background-image:url(/img/generic/bg-5.jpg);background-position: center bottom;">
      </div>
      <!--/.bg-holder-->

      <div class="container">
        <div class="row justify-content-center text-center pt-5 pt-lg-7 pb-lg-9 pb-xl-0"
          style="min-height: 50vh;">
          <div class="col-md-12">
            <h1 class="lead fs-2 fs-sm-4 fs-md-5 mb-3 pb-3 font-proxima font-weight-bold"
              style="background: rgba(0, 0, 0, 0.3);">
              Создание бизнес-планов<br> 
              еще не было таким простым и удобным!
            </h1>
          </div>
          <div class="col-md-12">
    
          </div>
          
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

     <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-light text-center pt-5">
      <div class="container">
        <h3 class="font-proxima title">
          Все, что необходимо для создания бизнес-плана!
        </h3>
        <div class="row">
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <h5 class="font-proxima text-left mb-3 pt-5">
              Автоматический расчет финансового прогноза
            </h5>
            <p class="font-proxima text-left">
              Сервис "<strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> 
              - в автоматическом режиме формирует ключевые таблицы финансового прогноза: 
              Баланс доходов и расходов, Баланс движения денежных средств, Прогнозный баланс. 
              Вы просто вводите данные, в удобной  и интуитивно понятной форме. все остальное 
              программа сделает за Вас.   
              <br> <br>
              Наличие грамотно и качественно составленные таблицы финансового прогноза, значительно 
              повысят Ваши на получение внешнего финансирования, т.к. рассказывает о Вашей идеи на языке
              понятном любому финансисту. 
            </p>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <div class="card p-0">
              <img src="@/assets/img/illustrations/auto_finance.png" 
                class="img-fluid" style="max-width: 100%; height: auto; border-radius: 5px;" 
                alt="Автоматический расчет финансов вашего бизнес-плана">
            </div>
            
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3 mt-5">
            <div class="card p-0">
              <img src="@/assets/img/illustrations/charts.png" 
                class="img-fluid" style="max-width: 100%; height: auto; border-radius: 5px;" 
                alt="Интерактивные графики вашего бизнес-плана">
            </div>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3 mt-4">
            <h5 class="font-proxima text-left mb-3 pt-5">
              Интерактивные графики 
            </h5>
            <p class="font-proxima text-left">
              Автоматически формируемые графики не только дополнят Ваш бизнес-план, но и позволят Вам 
              увидеть слабые места Вашей идеи и предпринять необходимые корректировки, чтобы это исправить. 
            </p>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3 mt-3">
            <h5 class="font-proxima text-left mb-3 pt-5">
              Интуитивно понятный редактор с подсказками и примерами
            </h5>
            <p class="font-proxima text-left">
              Редактор текста <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> 
              - место, где Вы с легкостью сможете выражать мысли и превращать идею в конкретные этапы плана. 
              А если вдруг возникнут сложность, то всегда можно обратиться к советам по написанию того или 
              иного абзаца, а также ознакомиться с примерами подобных текстов в других бизнес-планах.
            </p>
          </div>
          
          <div class="col-12 col-lg-6 order-lg-1 mb-3 mt-4">
            <div class="card p-0">
              <img src="@/assets/img/illustrations/rich_editor.png" 
                class="img-fluid" style="max-width: 100%; height: auto; border-radius: 5px;" 
                alt="">
            </div>
          </div>
          <div v-if="false"
            class="col-12 col-lg-6 order-lg-1 mb-3">
            <img src="@/assets/img/illustrations/feauture_SamplePlans.png" class="px-3" style="max-width: 100%; height: auto;" alt="">
          </div>
          <div v-if="false"
            class="col-12 col-lg-6 order-lg-1 mb-3">
            <h5 class="font-proxima text-left mb-3 pt-5">Более 100 готовых бизнес-планов</h5>
            <p class="font-proxima text-left">
               Зарядись вдохновение и разбуди в себе писателя с помощью нашей библиотеки готовых бизнес-планов, состоящей из более чем 100 готовых бизнес-планов, охватывающих все основные отрасли промышленности. С помощью <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> ты сэкономишь сотни часов, используя, в качестве примера, готовые планы как отправную точку для собственного плана, настраивая и адаптируя его по мере продвижения.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-6 mb-4">
            <h3 class="font-proxima font-weight-thin">
              А также еще много чего полезного!
            </h3>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Расчет финансовых показателей</h5>
            <p class="features">
              Больше никакой возни с формулами! Финансовые таблицы, диаграммы и отчеты рассчитываются автоматически.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Сравнительные показатели</h5>
            <p class="features">
              Узнайте, как соотносится ваша компания с другими по отрасли, размеру или расположению.
            </p>
          </div>
          <div v-if="false"
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Неограниченное количество бизнес-планов</h5>
            <p class="features">
              Больше не нужно ограничивать свой творческий потенциал. Создавай столько бизнес-планов, сколько нужно, никаких ограничений.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Пошаговые инструкции</h5>
            <p class="features">
              Наши инструкции позволят легко заполнить пробелы и помогают пройти весь процесс от начала и до конца.
            </p>
          </div>
          <div v-if="false"
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Более 100 готовых бизнес-планов
            </h5>
            <p class="features">
              В нашей библиотеке более 100 готовых бизнес-плана, которые можно использовать в качестве примера.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Доступ в любое время и в любом месте
            </h5>
            <p class="features">
              Вносите изменения в свой бизнес-план или отслеживайте эффективность ведения бизнеса откуда угодно, где есть интернет.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Контрольные точки
            </h5>
            <p class="features">
              Создайте контрольные точки ключевых событий вашего бизнес-плана и отслеживайте прогресс их достижения.
            </p>
          </div>
          <div
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Возможность экспорта в PDF
            </h5>
            <p class="features">
              Привлекательный и професионально оформленный бизнес-план легко сохраняется в PDF.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Кроссплатформенность
            </h5>
            <p class="features">
              <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> является облачным программным продуктом, а это значит, что его можно запускать на любом устройстве (Mac, Windows и даже планшете).
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Легкий экспорт</h5>
            <p class="features">
              Свободно экспортируйте свой бизнес-план в форматы Word или PDF. Питч можно также экспортировать в Power-Point для создания эффектного слайд-шоу.
            </p>
          </div>
          <div v-if="false"
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Поддержка экспертов</h5>
            <p class="features">
              Видеоуроки и разъяснение ключевых терминов от известных экспертов по бизнес-планированию.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Мониторинг показателей</h5>
            <p class="features">
              Визуальная панель мониторинга эффективности показателей, в режиме реального времени, позволяет отслеживать исполнение бюджетов и достижения целей по продажам.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Эффективная презентация</h5>
            <p class="features">
              Создайте резюме наполненное инфо-графикой для проведения "быстрых" презентаций своей идеи.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Клиентская поддержка</h5>
            <p class="features font-weight-thin">
              Если возникли затруднения, можно связаться с нашей службой поддержки по телефону, электронной почте или онлайн-чату.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Безопасность и уверенность
            </h5>
            <p class="features font-weight-thin">
              Ваши данные полностью зашифрованы и защищены. Гарантировано!
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <app-footer />

  </main>
</template>

<script>
import Navbar from '../Navbar'
import FooterMenu from '../FooterMenu';

export default {
  components: {
    appNavbar: Navbar,
    appFooter: FooterMenu
  }

}
</script>

<style lang="scss" scoped>

.title {
  border-bottom: 1px solid lightgray;
  font-weight: 100;
  padding-bottom: 5px;
  margin: 0 15rem 3rem 15rem; 
}

.chekmark {
  height: 50px; 
  width: 50px;
}

.features {
  max-width: 350px;
  width: 100%;
  font-family: 'Proxima Nova';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  margin: 0 auto 56px 0.4rem;
}

.feature-img {
  margin: 0 auto 26px;
  height: 300px;
  width: 300px;
  &.chekmark {
  height: 50px; 
  width: 50px;
}
}

.money-back-wrapper {
  max-width: 500px;
  margin: 0 auto 10px auto;
}

.money-back-badge {
    background-image:url(/img/illustrations/corner-4.png);
    // background-color: red;
    width: 60px;
    height: 60px;
    display: block;
    margin-right: 15px;
    position: relative;
    top: -5px;
}

.lead {
  background: rgba(200, 200, 200, 0.3);
  border-radius: 3px;
  padding: 3px 10px;
}

@media (max-width: 899px) {
  li {
    display: grid;
  }

}

@media (min-width: 990px) {
  li {
    margin-right: 5px;
  }
}

@media (max-width: 1199px) {
  .typed-center {
    align-items: center; 
    justify-content: center; 
    box-sizing: border-box;
  }
}

</style>